html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--palette-background-default);
  --font-family: 'Montserrat';
  font-family: 'Montserrat';
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    -webkit-scroll-behavior: auto;
    -moz-scroll-behavior: auto;
    -ms-scroll-behavior: auto;
    scroll-behavior: auto;
  }
}

dialog,
iframe {
  border: 0;
}

dialog {
  border: 0;
  margin: auto;
  overflow: visible;
}

form,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-decoration: none;
  text-decoration: none;
}
