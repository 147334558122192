@use 'sass:map';
$colors: (
  'highlight': #0af
);

@use 'styles/normalize';
@use 'app/core/ui/button/button';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: 'Montserrat';
  --font-family: 'Montserrat';
  color: #1c1a1b;
  font-weight: 600;
}
html,
body {
  margin: 0;
  --font-family: 'Montserrat';
  font-family: 'Montserrat';
  font-size: 13.5px;
}

.loader__progress {
  z-index: 120000;
  position: absolute !important;
  left: calc(50% - 50px);
  top: calc(50vh - 50px);
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

form.horizontal {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: flex-start;
  align-items: flex-end;
  .form-control,
  .form-button {
    max-width: 25%;
    min-width: 20%;
    flex: 1;
    .p-select,
    .p-multiselect,
    .p-calendar,
    .p-inputtext,
    .p-button,
    .p-inputnumber {
      width: 100%;
    }

    &--textarea {
      min-width: calc(75% - 5px);
      display: flex;
      max-width: 100%;
      flex: auto;
      textarea {
        width: 100%;
        resize: none;
      }

      &--full {
        width: 100%;
      }
    }
  }
  .form-chip {
    min-width: 50%;
    flex: 2;

    .p-chip {
      width: 100%;
    }
  }
  p-select,
  p-inputnumber,
  p-inputtext,
  p-multiselect {
    width: 100%;
  }
}

.p-picklist {
  padding: 5px 0;
}

.p-orderlist {
  padding: 25px 0;
  max-height: 70vh;
  overflow: hidden;
  .p-orderlist-list {
    height: 65vh;
    overflow: scroll;
    &.p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:hover {
      background: white;
      color: getColor('highlight');
    }
    .p-orderlist-item.p-highlight {
      color: getColor('highlight') !important;
      font-weight: 600px;
      background-color: white;
      border: 1.1px solid lightgray;
    }
  }
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 5px;

  img {
    flex: 1;
    width: 100%;
    max-width: 65px;
    height: auto;
  }

  &__info {
    flex: 3;
    display: grid;
    row-gap: 7px;

    &__stock {
      display: flex;
      align-items: center;
    }
  }
}

.products-list {
  i {
    display: none;
  }
  .p-picklist {
    max-height: 65vh;
    min-height: auto;

    .p-picklist-list {
      height: 60vh;
    }
  }
  .p-picklist-target i {
    display: block !important;
    cursor: pointer;
    margin-left: 5px;
    color: getColor('highlight');
  }
}

.p-dialog.maximized {
  width: 100%;
  height: 90vh;
  max-width: 900px;
}

// .p-dialog-content {
//   border-radius: 6px;
// }

.p-dialog.block-page .p-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-editable {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  td.left-padding {
    padding-left: 40px !important;
  }
  td .info {
    display: flex;
    align-items: center;
    column-gap: 5px;
    img {
      width: 50px;
      height: auto;
    }
  }
  td.right {
    text-align: right !important;
    padding-right: 30px !important;
  }
}

.product-cards {
  font-family: 'Montserrat', sans-serif !important;
  div {
    border-radius: 8px;
  }
}

.p-datatable-sm {
  font-family: 'Montserrat', sans-serif !important;
}

.draft-form {
  font-family: 'Montserrat', sans-serif !important;
}

.segmentation {
  font-family: 'Montserrat', sans-serif !important;

  .no-products {
    padding-top: 5px;
    font-weight: normal;
  }

  &__compositions {
    &__button {
      align-self: center;
      &--submit {
        margin: auto 0 0 auto !important;
        width: 100px !important;
      }
    }
    &__title {
      padding: 5px;
      margin-bottom: 5px;
    }
  }
  &__products {
    &__title {
      padding: 5px;
      margin-bottom: 5px;
    }
  }
  &__composition {
    &__form.horizontal {
      justify-content: flex-start;

      .form-control {
        max-width: 45%;
        min-width: 20%;
      }
    }

    &__products {
      &__product {
        padding-bottom: 5px;
      }
    }
  }
}

.dialog-composition-modal {
  background: #ffffff;
  padding-bottom: 1rem;
}

.admin-link {
  color: getColor('highlight');
  text-decoration: underline;
  cursor: pointer;
}

.small.p-multiselect-panel {
  width: 32rem;
}

.p-datatable-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .buttons {
    column-gap: 5px;
    display: flex;
  }
}

.p-datatable.no-filter .p-datatable-header {
  justify-content: flex-end;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::ng-deep.p-scroller {
  margin-top: 3px;
}

::ng-deep.p-scroller-content {
  padding-right: 3px;
  max-width: -webkit-fill-available;
}

.segmentation .p-virtualscroller .p-virtualscroller-header {
  display: flex;
  align-items: center;
  column-gap: 5px;

  input {
    width: 100%;
  }
}

.p-confirm-popup .p-confirm-popup-footer button {
  min-width: 80px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@function getColor($color-value) {
  @return map.get($colors, $color-value);
}
