.button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 12px 20px;
  position: relative;
  column-gap: 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;

  &.primary {
    background-color: #1c1a1b;
    color: white;
  }
}
